import React from 'react'

function BlogPost({post}) {
  return (
    <div>
        <div key={post.id} className="">
            <h2 className="">{post.title}</h2>
            <p className="blog-date">{post.date}</p>
            <article className="">{post.content}</article>
        </div>
  </div>
  )
}

export default BlogPost