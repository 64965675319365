import React from 'react';
import Accordion from '../components/Accordion';
import { accordionHorsebackData } from '../utils/accordionContent';
import horse from '../Images/horse.jpg'

import styles from './Horseback-Safaris.module.css'

const HorsebackSafaris = () =>{
  return (
    <div>
      <img src={horse} alt='horse' className={styles.horse} />
      
      <div className={styles.intro}>
          <article>Imagine galloping alongside the African wildlife, feeling the wind in your hair and the sun on your skin. Kenya is renowned for its magnificent wildlife and stunning landscapes, and there is no better way to experience it all than on horseback.</article>
          <article>Whether you are a seasoned equestrian or a beginner, our horseback safaris cater to all skill levels and offer a unique way to explore the wilderness. From the rolling savannah plains to the lush forests and mountain ranges, our horseback safaris provide a rare opportunity to connect with nature and witness the incredible wildlife in their natural habitats. So, get ready to embark on a once-in-a-lifetime adventure and discover the beauty of Kenya from a whole new perspective.</article>
      </div>

      <div className={styles.faqs}>
        <h5>Frequently asked questions</h5>
        {accordionHorsebackData.map(({title, content}) => (
          <Accordion title={title} content={content} />
        ))}
      </div>
    </div>
  );
}
export default HorsebackSafaris;