import React, { useState } from 'react';
import styles from './Accordion.module.css';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  const clickfaq = () => {
    setIsActive(!isActive)
  }

  return (
    <div>
      <div className={styles.accordionTitle} onClick={clickfaq}>
        <p>{title} <span>{isActive ?'-' :'+'}</span></p>
        
      </div>
      <div className={`${styles.accordionContent} ${isActive ? styles.active : ''}`}>
        {content}
      </div>
    </div>
  );
};

export default Accordion;