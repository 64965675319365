import React, { useEffect, useState } from 'react';
import './CookieBanner.css'

function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const dismissed = localStorage.getItem('cookieBannerDismissed');
    if (!dismissed) {
      setShowBanner(true);
    }
  }, []);

  const handleDismiss = () => {
    localStorage.setItem('cookieBannerDismissed', 'true');
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="cookie-banner">
        <p>
          This website uses cookies to enhance your user experience. By continuing to use this website, you agree to our use of cookies.
        </p>
        <button onClick={handleDismiss}>Dismiss</button>
      </div>
    )
  );
}

export default CookieBanner;

