import React, { useState } from 'react'
import { Button } from '@nextui-org/react'
import styles from './Contact-us.module.css'


const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    setError(null);
    try {
      await fetch('/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      setSuccess(true);
    } catch (error) {
      setError(error);
    } finally {
      console.log(formData)
      setIsSending(false);
    }
  };

  if (isSending) {
    return (
      <div className={styles.loading}>
        <p>Sending your message...</p>
      </div>
    )
  }

  if (success) {
    return <div className={styles.response}>
      <h5>Message sent successfully!</h5>
    </div> 
  }

  return (
    <div className={styles.contactHome}>
     {/*<p className={styles.p1}>Send us a message and one of our consultants will get back to you.</p>*/} 
    <form onSubmit={handleSubmit} className={styles.form} method='POST' action='https://contact-us-wandia.onrender.com/contact'>
      {error && <p>An error occurred: {error.message}</p>}
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="What's your name?"
        className={styles['input-text']}
        required
      />
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="What's your Email address?"
        className={styles['input-email']}
        required
      />
      <textarea
        name="message"
        value={formData.message}
        onChange={handleChange}
        placeholder="What would you like us to help you with?"
        className={styles['input-textarea']}
        required
      />
      <Button type="submit" className={styles['input-submit']} disabled={isSending}>
        Submit
      </Button>
    </form>
    </div>
  );
};

export default ContactUs;