import React from 'react'
import styles2 from './Testimonials.module.css'
import styles from './services.module.css'

function Services() {

  const handleClick = (e) => {
    e.preventDefault();
    window.location.href = 'mailto:info@example.com';
  };
  
  return (
    <>
      <div className={styles.introservices}>
        
        <article>
          At Wandia Safaris, we're passionate about sharing the beauty, culture, and natural wonders of Kenya with travelers from around the world. Our team of experienced guides, drivers, and travel experts are dedicated to ensuring that your trip is not only enjoyable, but also safe and responsible.
        </article>
        <article>
        Join us on a journey through Kenya's vibrant cities, vast savannas, and pristine beaches, and discover the magic of this incredible country for yourself. We can't wait to show you what Kenya has to offer!
        </article>
      </div>

      <div className={styles.offer}>
        <h4 className={styles2.font}>What we offer</h4>
            <ul>
              <li>Tour planning and itinerary creation</li>
              <li>Accommodation booking and reservations</li>
              <li>Transportation arrangements</li>
              <li>Safari game drives and wildlife viewing</li>
              <li>Cultural tours and experiences</li>
              <li>Special interest tours (birding, fishing, cycling etc)</li>
              <li>Guided hikes and nature tours</li>
              <li>Boat cruises and water-based activities</li>
              <li>Balloon safaris and scenic flights</li>
              <li>Travel insurance and visa application</li>
              <li>Car Hiring</li>
            </ul>
      </div>

      <div className={styles.interested}>
        <article>If you're interested in any of the above services, contact us at any time. We always have the time to cater to our clients issues.</article>
        <button onClick={handleClick}>Inquire</button>
      </div>
    </>
    
  )
}

export default Services