import React from 'react';
import './Blog.css'

const Blog = ({posts}) =>{
  const MAX_CONTENT_LENGTH = 200; // Set the maximum content length as 200 characters

  return (
    <div className="blog-feed">
      {posts.map(post => (

        <div key={post.id} className="blog-post">
          <h2 className="blog-title">{post.title}</h2>
          <p className="blog-date">{post.date}</p>
          <article className="blog-content">{post.content.slice(0, MAX_CONTENT_LENGTH)} .....</article>
          <a href={`/Blog/${post.id}`}>Read More</a>
        </div>
      ))}
    </div>
  
  );
}
export default Blog;