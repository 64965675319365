import React from 'react';
//import dude from '../Images/Aboutbackground.jpg'
import styles2 from './Testimonials.module.css'
import information from '../Images/information-pamphlet.png';
import travelstuff from '../Images/travelstuff.jpg';
import { Card, Text } from "@nextui-org/react";
import './About-us.css'

const AboutUs = () =>{
  return (
    <div>
      <div className='aboutusl'>
        <img src={travelstuff} alt="about us background, assorted travel stuff" />
      </div>
      <div className='aboutcontent'>
        <article>
        <h5 className={styles2.font}>Who we are  <img src={information} alt='information Pamphlet' width={20} height={20} /></h5>
        <article className='introWho'>
        Wandia Safaris is a travel agency founded by a group of passionate travelers who wanted to share their love of adventure with the world. 
        Our team has years of experience traveling to some of the most exotic and breathtaking destinations, and we've created tours that offer a unique blend of culture, history, and nature.
        Whether you're looking for a relaxing beach vacation, an exciting safari, or a thrilling hike through rugged terrain, we have a tour that's perfect for you. Join us on our journey, and discover the world in a way you never thought possible.
       </article>
       </article>
       {/*<div className='image'>
        <img src={dude} alt='dude' />
       </div>*/}
      </div>
      <div className='mvv'>
      <div className='mv'>
      <Card
      isPressable
      isHoverable
      variant="bordered"
      css={{ mw: "400px", boxShadow: "10px 10px 6px 5px #ccc" }}
    >
      <Card.Body>
        <h3>Mission</h3>
        <Text>Our mission is to provide travelers with unforgettable experiences by creating unique, tailor-made tours and trips that go beyond the ordinary.</Text>
      </Card.Body>
    </Card>
    <Card
      isPressable
      isHoverable
      variant="bordered"
      css={{ mw: "400px", boxShadow: "10px 10px 6px 5px #ccc" }}
    >
      <Card.Body>
        <h3>Vision</h3>
        <Text>Our vision is to be the travel agency of choice for adventurers, dreamers, and explorers looking for authentic and immersive experiences.</Text>
      </Card.Body>
    </Card>
      </div>
      <div className='values'>
      <Card
      isPressable
      isHoverable
      variant="bordered"
      css={{ mw: "400px", boxShadow: "10px 10px 6px 5px #ccc" }}
    >
      <Card.Body>
        <h3>Values</h3>
        <ul>
          <li>Curiosity:</li><p>We encourage our clients and employees to be curious, adventurous and to never stop exploring.</p>
          <li>Sustainability:</li><Text>We strive to minimize our environmental impact and to promote responsible tourism.</Text>
          <li>Authenticity:</li><Text>We believe in offering genuine and culturally rich experiences that go beyond the typical tourist routes.</Text>
          <li>Professionalism:</li><Text>We are committed to providing exceptional customer service and delivering on our promises.</Text>
        </ul>
      </Card.Body>
    </Card>
      </div>
      </div>
    </div>
  );
}
export default AboutUs;