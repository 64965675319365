import React from 'react';
import mara from '../Images/masaimara.jpeg';
import passport from '../Images/passportTransparent.gif'
import amboseli from '../Images/Amboseli.jpg';
import nairobi from '../Images/nairobinational.jpg';
import elephants from '../Images/elephants.jpg'
import Accordion from '../components/Accordion';
import styles2 from './Testimonials.module.css'
import { accordionWildlifeData } from '../utils/accordionContent';
import { FcFaq } from 'react-icons/fc'

import './Wildlife-safaris.css'
const WildlifeSafaris = () =>{
  return (
    <div className='wildlifeSafari'>
      <div className='safarisLandingPhoto'>
        <img src={elephants} alt='elephants'/>
      </div>
      <div className='overview'>
        <article>
          <article>A wildlife safari in Kenya is an unforgettable adventure that offers an opportunity to see some of the world's most magnificent animals in their natural habitats. During a safari, you can expect to see a variety of wildlife, including the "Big Five" (lions, elephants, leopards, buffalo, and rhinos), as well as other species such as zebras, giraffes, cheetahs, and more.</article>
          <article>The best time to visit Kenya for a wildlife safari depends on the region you plan to visit, as different areas have different peak seasons for wildlife viewing. However, generally, the months of July to October are considered to be the best time to see the famous wildebeest migration in the Maasai Mara.</article>
          <article>In Kenya, there are many different regions and parks to explore, each with its own unique attractions and characteristics. Some of the most popular destinations for wildlife safaris include the Maasai Mara, Amboseli, and Samburu National Reserves, as well as the Nairobi National Park, which is located within the city limits of Nairobi.
Whether you're a seasoned safari-goer or a first-time visitor, a wildlife safari in Kenya promises to be a once-in-a-lifetime experience that will provide memories to last a lifetime.</article>
        </article>
      </div>

      <div className='destinations' >
        <h4 className={styles2.font}>Destinations <img src={passport} alt='passport' className='passportgif' /> </h4>
        <article>
        Kenya is renowned for its rich wildlife and diverse landscapes, making it a top destination for wildlife safaris. Here are some of the most popular wildlife destinations in Kenya, along with details about their unique features and attractions:
<ul>
<div className='mara'>  
<li><h4>Maasai Mara</h4><article>The Maasai Mara is arguably one of Kenya's most famous wildlife destinations, renowned for its spectacular annual wildebeest migration. In addition to the wildebeest, you can also see a wide variety of other wildlife, including lions, elephants, giraffes, and more. The Maasai Mara also offers a chance to interact with the Maasai people and learn about their traditional culture.</article></li>
<img src={mara} alt='Maasai Mara'/>
</div>

<div className='Amboseli'>
<li><h4>Amboseli</h4><article>Amboseli is located at the foot of Mount Kilimanjaro, making it an ideal destination for those looking to combine wildlife viewing with scenic views of the mountain. In addition to its breathtaking landscapes, Amboseli is also home to a large elephant population and offers a unique opportunity to see these magnificent creatures up close.</article></li>
<img src={amboseli} alt='Amboseli'/>
</div>

<div className='Samburu'>
<li><h4>Samburu</h4> <article>The Samburu Reserve is known for its unique wildlife, including the gerenuk, the Somali ostrich, and the reticulated giraffe. It is also home to several large herds of elephants and offers a chance to see big cats such as lions and leopards in their natural habitats. The reserve is also located in a remote and largely untouched area of northern Kenya, making it an ideal destination for those seeking a more immersive wildlife experience.</article></li>
<img src={nairobi} alt="Samburu national Park"/>

</div>

<div className='nairobi'>
<li><h4>Nairobi National Park</h4><article>Located within the city limits of Nairobi, the Nairobi National Park offers a unique chance to see wildlife in a more urban setting. The park is home to a wide variety of wildlife, including lions, giraffes, and over 400 species of birds, and is also an important sanctuary for endangered species such as the black rhino.</article></li> 
<img src={nairobi} alt="Nairobi national Park"/>
</div>

</ul>
        </article>
      </div>

      <div className='faqs'>
        <h5>Frequently Asked Questions. <FcFaq/></h5>
        <div className='accordion'>
          {accordionWildlifeData.map(({title, content}) => (
            <Accordion title={title} content={content} />
          ))}
        </div>
        
      </div>
      

    </div>
  );
}
export default WildlifeSafaris;