import React, {/*{ useState, useRef, useEffect, }*/}from 'react';
//import Homepic from '../Images/SafariHome1.jpg';
import kwsLogo from '../Images/kenya-wildlife-service-logo.png';
import MagicalKenyaLogo from '../Images/magical-kenya.svg';
import black from '../Images/black.png'
import sunrise from '../Images/sunrise.png';
import travelbag from '../Images/travel-bag.png';
//import vehicle from '../Images/travel.png';
import travelstuff2 from '../Images/travelstuff2.jpg';
import flatlay from '../Images/flat-lay.jpg'
//import ocean from '../Images/ocean.jpg'
import { Swiper, SwiperSlide } from "swiper/react";
//import { Pagination, Navigation, Autoplay } from "swiper";
import SwiperCore, { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/core';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Button } from "@nextui-org/react"
import { Image } from '@nextui-org/react';
import styles2 from './Testimonials.module.css'
import { ReactComponent as Travel1 } from '../Images/mega-creator.svg';
import styles from './Home.module.css';

SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade])


const Home = () =>{

  {/*const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current);
        }
      });
    });

    observer.observe(ref.current);}

  , []);*/}

  return (
    <div className={styles.Home}>
      {/*<Swiper
        slidesPerView={1}
        
        autoplay={{delay:8000}}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><Image
      width="100%"
      height="420px"  
      src={travelstuff2}
      alt="ocean"
      objectFit="cover"
      />*/}

      <div className={styles.landingPhoto}>
        <div className={styles.slogan}>
          <h1 className={styles2.font}>Travel With Us</h1>
        </div>
      <Swiper
        slidesPerView={1}
        
        autoplay={{delay:8000}}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide><Image
      width="100%"
      height="450px"  
      src={flatlay}
      alt="ocean"
      objectFit="cover"
      /></SwiperSlide></Swiper>
        <img src={travelstuff2} alt="getting ready to travel" className={styles.travelstuffimg}/>
      </div>
      
      <div>
        
      <article className={styles.trademark}><img src={sunrise} alt='sunrise' className={styles.sunrise} width={40} height={40}/><strong>Escape the ordinary and embark on an adventure that will leave you in awe.</strong> <img src={travelbag} alt='sunrise' width={40} height={40}/></article>
        <article className={styles.intro}>
          Let us take you to breathtaking destinations, where memories are made and stories are born.
          We are your trusted travel agency, providing personalized experiences that will feed your soul and ignite your wanderlust. 
          Say yes to spontaneous road trips, cultural immersions, and bucket-list destinations. Let us craft your dream journey and make it a reality.
          Your journey starts here, so pack your bags and let's go on an adventure together.
        </article>
        {/*<div className={styles.vehicleContainer}>
          <img src={vehicle} alt='vehicle' className={`${isVisible ? '.is-visible' : ''}`} width={35} height={35} ref={ref}/>
      </div>*/}
      </div>
      
      <div className={styles.TestimonialsContainer}>
      <div className={styles.leftside}>
       <h4>What our clients say about us</h4> 
      <Button className={styles.testimonialsButton}><a className={styles.Testimonials} href='/Testimonials'>Discover more</a></Button>
      </div>
      <div className={styles.rightside}>
        <article>"I recently took a trip to Kenya with this travel agency and I was blown away by their attention to detail. From the airport pick-up to the stunning wildlife viewing, everything was perfect. Thanks to David for making my trip so special."</article>
        <span> - Jessie L</span>
      </div>
      </div>
      <hr></hr>
      <div className={styles.PartnersContainer}>
        <h4 className={styles2.font}>Our Partners</h4>
        <div className = {styles.PartnersImages}>

        <Image  
          width='200px'
          height='194px'
          src={MagicalKenyaLogo}
          alt="Magical Kenya Logo"
          objectFit="fill"
          />

        <Image  
          width='200px'
          height='200px'
          src={kwsLogo}
          alt="KWS Logo"
          objectFit="fill"
          />
        </div>
      </div>
      <hr></hr>
      <div>
        <div className={styles.outro}>

        <div className={styles.outro1}>  
          <article>At Wandia Safaris, we believe that travel is more than just going to new places. It's about experiencing new cultures, trying new things, and creating lifelong memories with the people you love.</article>
          <Travel1 className={styles.travel1svg}/>
        </div>

        <div className={styles.outro2}>
        <article>That's why we offer a wide range of travel packages to destinations all over Kenya, from exotic beach getaways to cultural city breaks and everything in between.

Our packages are designed to cater to a variety of interests, budgets, and travel styles, whether you're looking for a romantic honeymoon, a family-friendly adventure, or a solo escape. We take care of all the details, so you can focus on enjoying your trip to the fullest.</article>

</div>

<div className={styles.outro3}>
<div className={styles.outro3img}>
  <img src={black} alt="travel agent" className={styles.blackguy}/>
</div>
<article>Our team of travel experts is always here to help you plan your dream vacation. We pride ourselves on providing exceptional customer service and personalized travel recommendations based on your unique preferences and needs. We are committed to making your travel experience as seamless and stress-free as possible, from the moment you book until the end of your trip.</article>
</div>

    <div className={styles.outro4}>
    <article>Explore our website to discover our range of travel packages and start planning your next adventure today. Let us help you make your travel dreams a reality!</article>
    </div>
        </div>
      </div>
    </div>
  );
}
export default Home;