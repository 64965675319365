import React, { useState, useEffect } from 'react';
import './CTA.css'

const CTA = () => {
    const [time, setTime] = useState(new Date().toLocaleTimeString());

    let opening = new Date()
    opening.setHours(8,0,0,0)
    let openingTime = opening.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })


    let closing = new Date()
    closing.setHours(17,0,0,0)
    let closingTime = closing.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })


    useEffect(() => {
      const intervalId = setInterval(() => {
        setTime(new Date().toLocaleTimeString());
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);
    return (
        <div className='cta'>
            <div className='right'>
                <span className='wh'><strong>Working hours</strong>: {openingTime} - {closingTime} </span><span className='time'>{time}</span>
            </div>
            <div className='left'>
                <span className='contact'>&#9743; <strong>Contact us</strong>: 0797139647</span>
            </div>

        </div>
    )
}

export default CTA