export const accordionWildlifeData = [{
    title: "What should I pack for a wildlife safari in Kenya?",
    content: "Comfortable, lightweight clothing in neutral colors (such as beige, tan, or green) is recommended to blend in with the environment and reduce the chances of disturbing the wildlife. You'll also need a good pair of walking shoes, a hat, and sunglasses to protect yourself from the sun. Additionally, insect repellent, sunblock, and a camera are essential for capturing the memories of your safari."
},
{
    title: "How can I prepare for a wildlife safari in Kenya?",
    content: "Researching the different regions and parks you plan to visit and familiarizing yourself with the wildlife you may encounter is a good place to start. You may also want to consider getting in shape before your trip by doing some light exercise, such as walking or jogging, to prepare yourself for the physical demands of the safari."
},
{
    title: "What should I expect while on a wildlife safari in Kenya?",
    content: "Wildlife safaris in Kenya typically involve early morning and late afternoon game drives, during which you'll be able to see the most active wildlife. During the middle of the day, you'll have some free time to relax and recharge, either at your camp or lodge. You can also opt for additional activities, such as guided walks or hot air balloon rides, to enhance your wildlife experience. "
},
{
    title: 'What should I do if I encounter a dangerous animal while on a safari?',
    content: "Your safari guide is trained to handle such situations and will be able to provide you with the necessary safety precautions. In general, it's best to stay calm, follow your guide's instructions, and keep a safe distance from any potentially dangerous animals."
},
{
    title: 'What is the accommodation like during a wildlife safari in Kenya? ',
    content: "Accommodation during a wildlife safari in Kenya ranges from basic camping to luxurious lodges, depending on your preferences and budget. Many lodges and camps offer comfortable and well-appointed rooms with en suite bathrooms, as well as shared spaces for dining, relaxing, and taking in the views."
},
{
    title: 'How do I get to my safari destination in Kenya?',
    content: "Most wildlife safaris in Kenya start and end in Nairobi, which is served by Jomo Kenyatta International Airport. From there, you'll typically travel by road to the different parks and reserves, either in a private vehicle or as part of a group. Some lodges and camps may also offer direct flights to their locations."
},
{
    title: "What is the best time to go on a wildlife safari in Kenya?",
    content: "Kenya's wildlife viewing is best during the dry season, which typically lasts from June to October. During this time, the vegetation is sparser, making it easier to spot wildlife, and there is less risk of malaria. However, the best time to visit will depend on what you hope to see and what your specific interests and needs are."
},
{
    title: "What kind of transportation is used during a wildlife safari in Kenya?",
    content: "Most wildlife safaris in Kenya use 4x4 vehicles, which provide an elevated view of the surrounding landscapes and wildlife. The vehicles are equipped with open roofs, allowing for optimal viewing and photography opportunities. Some lodges and camps may also offer alternative transportation options, such as hot air balloon rides or horseback safaris."
},
{
    title: "What should I do if I get sick while on a safari in Kenya?",
    content: "Your safari guide will be trained in first aid and will be able to provide basic medical care in the event of an emergency. In case of a more serious illness, you'll typically be transported to the nearest hospital for further treatment. It's also a good idea to purchase travel insurance before your trip, which will provide you with coverage for any medical expenses you may incur while on safari."
},
{
    title: "Is it safe to travel to Kenya for a wildlife safari?",
    content: "Kenya is generally a safe destination for tourists, but as with any travel, it's important to exercise caution and be aware of your surroundings at all times. Your safari guide will be able to provide you with information about the current security situation and any areas to avoid, and many lodges and camps have 24-hour security for added peace of mind."
},
]

export const accordionHorsebackData = [{
    title: "What is a horseback safari in Kenya, and how is it different from a traditional safari?",
    content: "A horseback safari in Kenya is a unique way to explore the wilderness areas and national parks of Kenya. It involves riding on horseback through the grasslands, bush, and forests, giving you a chance to experience the natural environment and wildlife from a different perspective. Unlike traditional safaris where you are driven around in a vehicle, a horseback safari allows you to get up close to wildlife without the noise and disturbance of a vehicle."
},
{
    title: "Do I need to be an experienced rider to go on a horseback safari in Kenya?",
    content: "It's generally recommended that you have some riding experience before going on a horseback safari in Kenya, as the terrain can be challenging and the horses may be energetic. However, there are some safaris that cater to beginners and offer lessons before heading out on a ride."
},
{
    title: "What kind of wildlife can I expect to see on a horseback safari in Kenya?",
    content: "On a horseback safari in Kenya, you can expect to see a wide range of wildlife, including elephants, giraffes, zebras, wildebeests, antelopes, and many species of birds. You may also have the opportunity to spot predators such as lions, leopards, and hyenas."
},
{
    title: " What is the best time of year to go on a horseback safari in Kenya?",
    content: "The best time of year to go on a horseback safari in Kenya is during the dry season, which runs from June to October. This is when the grass is shorter, making it easier to spot wildlife, and the weather is mild and comfortable for riding. The rainy season, from November to May, can be muddy and challenging for riding, although it also brings lush greenery and newborn wildlife.",
},
{
    title: "What safety precautions should I be aware of before going on a horseback safari in Kenya?",
    content: "Safety is a top priority on horseback safaris in Kenya, and reputable tour operators will take all necessary precautions to ensure your safety. However, it's important to be aware of the risks and follow the instructions of your guide. This may include staying a safe distance from wildlife, riding at a controlled pace, and being prepared for unexpected situations such as sudden weather changes or a spooked horse."
},
]