import React from 'react';
import { Card, Grid, Text, Avatar} from "@nextui-org/react";
import styles from './Testimonials.module.css'


const Testimonials = () => {
    return (
        <div>
          <h5 className={styles.font}>Reviews</h5>
        <div className={styles.container1}>  
        <Card css={{ p: "$6", mw: "400px" }}>
        <Card.Header>
        <Avatar 
          rounded 
          src="https://i.pravatar.cc/150?u=a042581f4e29026023d" />
          <Grid.Container css={{ pl: "$6" }}>
            <Grid xs={12}>
              <Text h4 css={{ lineHeight: "$xs" }}>
                Jessica L.
              </Text>
            </Grid>
            <Grid xs={12}>
              {/*<Text css={{ color: "$accents8" }}>nextui.org</Text>*/}
            </Grid>
          </Grid.Container>
        </Card.Header>
        <Card.Body css={{ py: "$2" }}>
          <Text>
          "I recently took a trip to Kenya with this travel agency and I was blown away by their attention to detail. From the airport pick-up to the stunning wildlife viewing, everything was perfect. Thanks to David for making my trip so special."
          </Text>
        </Card.Body>
        <Card.Footer>
        </Card.Footer>
      </Card>


      <Card css={{ p: "$6", mw: "400px" }}>
        <Card.Header>
        <Avatar 
          rounded 
          src="https://i.pravatar.cc/150?u=a042581f4e29026022d" />
          <Grid.Container css={{ pl: "$6" }}>
            <Grid xs={12}>
              <Text h4 css={{ lineHeight: "$xs" }}>
                Jessica Lisborn
              </Text>
              
            </Grid>
            <Grid xs={12}>
              {/*<Text css={{ color: "$accents8" }}>nextui.org</Text>*/}
            </Grid>
          </Grid.Container>
        </Card.Header>
        <Card.Body css={{ py: "$2" }}>
          <Text>
          "My family and I had the time of our lives on our Kenyan safari with this travel agency. The guides were fantastic and the accommodations were top notch. I would definitely use this agency again. Thanks, John!" 
          </Text>
        </Card.Body>
        <Card.Footer>
        </Card.Footer>
      </Card>


      <Card css={{ p: "$6", mw: "400px" }}>
        <Card.Header>
        <Avatar 
          rounded 
          src="https://i.pravatar.cc/150?u=a042581f4e29026025d" />
          <Grid.Container css={{ pl: "$6" }}>
            <Grid xs={12}>
              <Text h4 css={{ lineHeight: "$xs" }}>
                Martin Kieran.
              </Text>
            </Grid>
            <Grid xs={12}>
              {/*<Text css={{ color: "$accents8" }}>nextui.org</Text>*/}
            </Grid>
          </Grid.Container>
        </Card.Header>
        <Card.Body css={{ py: "$2" }}>
          <Text>
          "I was a bit hesitant to book a trip with a new travel agency, but I'm so glad I took the leap with this one. From start to finish, the staff, especially Jane, went above and beyond to make sure that my trip was memorable. Thank you!"           </Text>
        </Card.Body>
        <Card.Footer>
        </Card.Footer>
      </Card>
      </div>


      <div className={styles.container2}>
      <Card css={{ p: "$6", mw: "400px" }}>
        <Card.Header>
        <Avatar 
          rounded 
          src="https://i.pravatar.cc/150?u=a042581f4e29026024d" />
          <Grid.Container css={{ pl: "$6" }}>
            <Grid xs={12}>
              <Text h4 css={{ lineHeight: "$xs" }}>
                Mike Jabowdi.
              </Text>
            </Grid>
            <Grid xs={12}>
              {/*<Text css={{ color: "$accents8" }}>nextui.org</Text>*/}
            </Grid>
          </Grid.Container>
        </Card.Header>
        <Card.Body css={{ py: "$2" }}>
          <Text>
          "I can't say enough good things about this travel agency. From the amazing cultural experiences to the unforgettable wildlife sightings, I had the trip of a lifetime. Thanks to everyone, especially Rachel, for making my dreams come true."           </Text>
        </Card.Body>
        <Card.Footer>
        </Card.Footer>
      </Card>


      <Card css={{ p: "$6", mw: "400px" }}>
        <Card.Header>
        <Avatar 
          rounded 
          src="https://i.pravatar.cc/150?u=a042581f4e29026027e" />
          <Grid.Container css={{ pl: "$6" }}>
            <Grid xs={12}>
              <Text h4 css={{ lineHeight: "$xs" }}>
                John Bolton.
              </Text>
            </Grid>
            <Grid xs={12}>
              {/*<Text css={{ color: "$accents8" }}>nextui.org</Text>*/}
            </Grid>
          </Grid.Container>
        </Card.Header>
        <Card.Body css={{ py: "$2" }}>
          <Text>
          "I recently took a trip to Kenya with this travel agency and I couldn't be happier with my experience. The staff, led by Thomas, were knowledgeable, friendly, and made sure that my trip was everything I wanted it to be. Highly recommend!"           </Text>
        </Card.Body>
        <Card.Footer>
        </Card.Footer>
      </Card>
      </div>

        </div>
    )
}

export default Testimonials;