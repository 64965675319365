import React from "react";
import { Navbar, Link, Text} from "@nextui-org/react";
import globe from '../Images/globe.png'

const Navigationbar = () => {
  
  return (
      <Navbar isBordered mobile variant="floating">
        <Navbar.Toggle aria-label="toggle navigation" showIn='xs' />
        <Navbar.Brand>
          
          <Text b color="inherit" hideIn="xs">
            Wandia Safaris
          </Text>
        </Navbar.Brand>
        <Navbar.Content hideIn="xs">
          <Navbar.Link href="/">Home</Navbar.Link>
          <Navbar.Link href="/About-us">About us</Navbar.Link>
          <Navbar.Link href="/Services">Services</Navbar.Link>
          <Navbar.Link href="/Wildlife-Safaris">Wildlife safaris</Navbar.Link>
          <Navbar.Link href="/Horseback-Safaris">Horseback safaris</Navbar.Link>
          <Navbar.Link href="/Adventure">Adventure</Navbar.Link>
          <Navbar.Link href="/Blog">Blog</Navbar.Link>
          <Navbar.Link href="/Contact-us">Contact us</Navbar.Link>
        </Navbar.Content>

        <Navbar.Content>
          <img src={globe} alt='globe' width={40} height={40}/> 
        </Navbar.Content>
        
        <Navbar.Collapse>
            <Navbar.CollapseItem key={0}>
                <Link
                color="inherit"
                css={{
                    minWidth: "100%",
                }}
                href="/"
                >
                Home
                </Link>
            </Navbar.CollapseItem>
            <Navbar.CollapseItem key={1}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/About-us"
            >
              About us
            </Link>
          </Navbar.CollapseItem>

          <Navbar.CollapseItem key={1}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/Services"
            >
              Services
            </Link>
          </Navbar.CollapseItem>    

          <Navbar.CollapseItem key={2}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/"
            >
              Wildlife safaris
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key={3}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/Horseback-Safaris"
            >
              Horseback safaris
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key={4}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/Adventure"
            >
              Adventure
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key={1}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/Blog"
            >
              Blog
            </Link>
          </Navbar.CollapseItem>

          <Navbar.CollapseItem key={1}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/Contact-us"
            >
              Contact us
            </Link>
          </Navbar.CollapseItem>

        </Navbar.Collapse>
      </Navbar>
  )
}

export default Navigationbar;