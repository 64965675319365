import React from 'react';
import { Link } from 'react-router-dom';
//import { Link } from 'react-router-dom'
import styles from './Footer.module.css'
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'
import { MdLocalPostOffice } from 'react-icons/md'
import { ImOffice } from 'react-icons/im'
import { BiMailSend } from 'react-icons/bi'

const Footer = () => {
    const year = new Date().getFullYear();

    return (
    <footer className={styles.foot}>
      <div className={styles.footertop}>
        <div>
          <h5>Follow us</h5>
          <span><FaFacebook /></span>
          <span><FaTwitter/></span>
          <span><FaInstagram/></span>

        </div>
        <div className={styles.contactfooter}>
          <h5>Contact us</h5>
          <p><span>Office <ImOffice/></span>: Kileleshwa</p>
          <p><span>Email <BiMailSend/></span>: info@wandiasafaris.co.ke</p>
        </div>
        <div className={styles.credits}>
          <h5>Credits (attributions)</h5>
          <a href="https://www.flaticon.com/free-icons/travel" title="travel icons">Travel icons - Flaticon</a>
          <a href="https://www.pexels.com" title="Images">Images - Pexels</a>

        </div>
        <div className={styles.Newsletter}>
          <h5>Subscribe to our newsletter <span><MdLocalPostOffice/></span></h5>
        
          <input type='email' placeholder='E-mail' className={styles['input-email']}></input>
        
        </div>
      </div>
      <span>&copy; <span>{year}</span> All Rights Reserved.</span>
      <hr />
      <div className={styles.policies}>
      <span><Link to='/Privacy-Policy'>Privacy Policy</Link></span>
      <span><Link to='/Cookie-Policy'>Cookie Policy</Link></span>
      <span><Link to='/Terms-Of-Service'>Terms of Service</Link></span>
      
      </div>
    </footer>
    )
}

export default Footer;