import React from 'react'
import './TermsOfService.css'

const TermsOfService = () => {
    return (
        <div className='terms'>
            <h3>Terms of Service</h3>
            <article>
                    <span>Last updated: February 7, 2023</span>

                    <h3>Introduction</h3>
                    <article>Welcome to Wandia Safaris, a website operated by Wandia Tours and Safaris LTD (“we”, “us”, “our”). This page sets forth the terms and conditions (“Terms of Service”) that govern your use of our website and any services offered by us. By using this website and/or any of our services, you agree to be bound by these Terms of Service. If you do not agree to these Terms of Service, please do not use this website or our services.</article>

                    <h3>Services</h3>
                    <article>We provide travel services, including but not limited to booking travel arrangements such as flights, hotels, and car rentals (“Services”). Our Services are offered subject to availability and are designed to help you plan and book your travel.</article>

                    <h3>Website use</h3>
                    <article>
                    You may use our website and the Services offered through it only for lawful purposes and in accordance with these Terms of Service. You agree not to use this website:
                    <ul>
                    <li>In any way that violates any local, or international law or regulation.</li>
                    <li>To impersonate or attempt to impersonate another person or entity.</li>
                    <li>To engage in any activity that is harmful to our website or Services or to any user of our website or Services.</li>
                    <li>To transmit any viruses, malware, or other harmful code or material.</li>
                    <li>To use our website or Services for any commercial or business purposes, unless we have given you prior written consent.</li>
                    </ul>
                    </article>

                    <h3>Booking and Payment Terms</h3>
                    <article>
                    All bookings are subject to availability and confirmation.
                    Payment for all Services must be made in advance.
                    Prices and availability are subject to change without notice.
                    All payments are non-refundable, unless otherwise specified in writing.
                    We are not responsible for any additional fees or charges imposed by airlines, hotels, car rental companies, or other travel providers.
                    Changes and Cancellations
                    If you need to change or cancel a booking, you must contact us as soon as possible.
                    Changes or cancellations are subject to the terms and conditions of the applicable travel provider and may result in additional fees.
                    We are not responsible for changes or cancellations made by travel providers.
                    </article>
                    <h3>Responsibility and Liability</h3>
                    <article>
                    We do not own or control any of the travel providers whose products and services are offered through our website and Services.
                    We are not responsible for the acts or omissions of any travel provider, including but not limited to, their failure to provide the services you have booked.
                    We are not responsible for any loss, damage, or injury that you may incur as a result of your use of our Services, including but not limited to, any loss, damage, or injury that results from the acts or omissions of any travel provider.
                    Your use of our Services is at your own risk.
                    </article>
                    <h3>Indemnification</h3>
                    <article>You agree to indemnify, defend, and hold harmless Wandia tours and safaris LTD, its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your use of our Services.</article>

                    <h3>Disclaimer of Warranty</h3>
                    <article>Our Services are provided on an “as is” and “as available” basis, without any warranties of any kind, either express or implied. We do not guarantee that our Services will be uninterrupted or error-free.</article>
            </article>
        </div>
    )
}


export default TermsOfService;