import React from 'react';
import { Modal, useModal, Button, Card, Grid, Text } from "@nextui-org/react";
import masaiguy from '../Images/masaiguy.jpg';
import scubadiving from '../Images/scubadiving.jpg';
//import birdwatching from '../Images/birdwatching.jpg';
import adventuresports from '../Images/adventuresports.jpg';
import wildlifesafaris from '../Images/wildlife-safaris.jpg';
import mountainclimbing from '../Images/mountainclimbing.jpg';
import culturaltours from '../Images/culturaltours.jpg';
import beachholidays from '../Images/beachholidays.jpg';
import maasaimara from '../Images/masaimara.jpeg'
import './Adventure.css';


const Adventure = () =>{
  const { setVisible, bindings } = useModal();


  const list = [
    {
      title: "Wildlife Safaris",
      img: wildlifesafaris,
      desc: "Kenya is famous for its incredible wildlife, including lions, elephants, giraffes, and more. Visitors can go on wildlife safaris to explore the country’s many game reserves and national parks, and see some of the world’s most magnificent animals in their natural habitats.",
    },
    {
      title: "Mountain Climbing",
      img: mountainclimbing,
      desc: "Mount Kenya is one of Africa’s most iconic peaks, and is a popular destination for mountain climbers. With its stunning scenery and challenging routes, climbers of all levels can enjoy the thrill of reaching the summit of this majestic mountain.",
    },
    {
      title: "Cultural tours",
      img: culturaltours,
      desc: "Kenya is home to a rich and diverse cultural heritage, and visitors can learn more about the country’s traditional customs and beliefs through cultural tours. These tours may include visits to Maasai villages, tribal dances, and other cultural events.",
    },
    {
      title: "Beach Holidays",
      img: beachholidays,
      desc: "Kenya is renowned for its beautiful beaches, and visitors can spend their days lounging on the sand and soaking up the sun. Whether you’re looking for a romantic getaway or a family-friendly vacation, Kenya’s beaches have something for everyone.",
    },
    {
      title: "Bird Watching",
      //img: birdwatching,
      desc: "Kenya is a birder’s paradise, and visitors can see some of the world’s most exotic and beautiful bird species in their natural habitats. From the flamingos of Lake Nakuru to the pelicans of Lake Naivasha, Kenya has an incredible variety of birds for visitors to enjoy.",
    },
    {
      title: "Scuba diving",
      img: scubadiving,
      desc: "Kenya’s stunning coastline offers some of the best diving opportunities in the world, and visitors can explore the country’s vibrant underwater world. With its rich marine life and crystal-clear waters, Kenya is a popular destination for scuba divers.",
    },
    {
      title: "Adventure Sports",
      img: adventuresports,
      desc: "For adrenaline-seekers, Kenya has plenty of adventure sports to offer. From bungee jumping and white-water rafting to sky-diving and rock-climbing, visitors can experience the thrill of a lifetime in Kenya.",
    },
    {
      title: "Maasai Mara National Reserve",
      img: maasaimara,
      desc: 'This world-famous reserve is home to a huge variety of wildlife, including the "Big Five" (lions, elephants, buffalo, leopards, and rhinos). Visitors can take part in guided game drives to see the wildlife up close, and perhaps even witness the legendary wildebeest migration. In addition to the wildlife, the Maasai Mara also offers opportunities for hot air ballooning and cultural visits with the Maasai people.',
    },
  ];

  return (
    <div className='adventure'>
      
      <div className='toppic'>
        <img src={masaiguy} alt="Epic Masai guy"/>
      </div>

      <article className='toparticle'>
        <article> Discover the beauty of the world and escape the monotony of everyday life with our travel agency.</article> 
        <article> Let us take you on an unforgettable journey filled with stunning landscapes, rich cultures, and a wealth of experiences that will leave you in awe.</article>
        <article> Our experienced travel experts will help you create personalized itineraries that cater to your unique interests and travel style. Whether you're seeking adventure, relaxation, or a combination of both, we've got you covered.We will help you discover the destinations of your dreams. </article>
        <article> Forget about the stress of planning and let us handle the logistics so you can fully immerse yourself in your travels. From flights to accommodations, we will ensure that every aspect of your journey is seamless and enjoyable. We take pride in delivering exceptional service and ensuring that every traveler has an unforgettable experience.
        So what are you waiting for? Pack your bags, gather your wanderlust, and let us take you on the journey of a lifetime. Your adventure starts now! </article>
      </article>
    
    <div>

    <Modal
      scroll
      fullScreen
      closeButton
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      {...bindings}
    >
      <Modal.Header>
        <Text id="modal-title" size={18}>
          Travel Itinerary
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Text id="modal-description">
        <h5>Day 1: Arrival in Nairobi</h5>

<p>Arrive at Jomo Kenyatta International Airport in Nairobi and be met by our representative.</p>
<p>Transfer to the hotel for check-in and overnight stay.</p>

<h5>Day 2: Nairobi City Tour</h5>

<p>Breakfast at the hotel.</p>
<p>Explore Nairobi city and visit the Karen Blixen Museum, a historic house that was once home to the famous Danish author of Out of Africa.
Continue to the Nairobi National Museum, showcasing Kenya’s rich cultural heritage and history.</p>
<p>Lunch at a local restaurant.</p>
<p>Visit the Giraffe Center, where you can get up close and personal with the majestic creatures, and feed them by hand.</p>
<p>Overnight stay in Nairobi.</p>

<h5>Day 3: Drive to Amboseli National Park.</h5>

<p>Early breakfast at the hotel.</p>
<p>Depart Nairobi and drive to Amboseli National Park, which is located at the foothills of Mount Kilimanjaro, Africa’s highest peak.</p>
<p>Arrive at the camp in time for lunch and relax for the afternoon.</p>
<p>Go on an evening game drive and enjoy the park’s incredible biodiversity, including large herds of elephants, lions, leopards, cheetahs and more.</p>
<p>Dinner and overnight stay at the camp.</p>

<h5>Day 4: Game Drives in Amboseli National Park.</h5>

<p>Early breakfast</p>
<p>Go on morning and afternoon game drives, exploring the park’s vast savannas and swamps.</p>
<p>Take a picnic lunch and enjoy a scenic lunch break.</p>
<p>Dinner and overnight stay at the camp.</p>
<h5>Day 5: Drive to Lake Naivasha.</h5>

Breakfast at the camp
Depart Amboseli and drive to Lake Naivasha, a freshwater lake surrounded by lush greenery and abundant wildlife.
Arrive at the camp in time for lunch and relax for the afternoon.
Take a boat ride on the lake to see its many species of birds, hippos and more.
Dinner and overnight stay at the camp.
<h5>Day 6: Visit Hell's Gate National Park</h5>

<p>Breakfast at the camp</p>
<p>Depart Lake Naivasha and drive to Hell's Gate National Park, where you can explore its unique geothermal activity, including hot springs, geysers, and boiling mud pools.</p>
<p>Go on a guided hike, taking in the stunning scenery, and visit the park's famous Olkaria Geothermal Station.</p>
<p>Lunch at a local restaurant.</p>
<p>Return to the camp in the evening for dinner and overnight stay.</p>
<h5>Day 7: Drive to Maasai Mara National Reserve.</h5>

<p>Breakfast at the camp</p>
<p>Depart Lake Naivasha and drive to Maasai Mara National Reserve, one of Kenya's most popular game reserves and home to some of Africa's most magnificent wildlife.</p>
<p>Arrive at the camp in time for lunch and relax for the afternoon.</p>
<p>Go on an evening game drive, taking in the incredible array of wildlife that can be found in the reserve.</p>
<p>Dinner and overnight stay at the camp.</p>
<h5>Day 8: Game Drives in Maasai Mara National Reserve.</h5>

<p>Early breakfast.</p>
<p>Go on morning and afternoon game drives, exploring the park’s vast savannas and swamps.</p>
<p>Take a picnic lunch and enjoy a scenic lunch break.</p>
<p>Dinner and overnight stay at the camp.</p>
<h5>Day 9: Return to Nairobi</h5>

<p>Breakfast at the camp.</p>
<p>Depart Maasai Mara and drive back to Nairobi.</p>

        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button flat auto color="error" onPress={() => setVisible(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  <div>
  <Grid.Container gap={2} justify="flex-start">
      {list.map((item, index) => (
        <Grid xs={6} sm={3} key={index}>
          <Card isPressable>
            <Card.Body css={{ p: 0 }}>
              <Card.Image
                src={item.img}
                objectFit="cover"
                width="100%"
                height="220px"
                alt={item.title}
              />
              <div className='title'>
                <h5>{item.title}</h5>
              </div>
              <article>{item.desc}</article>
            </Card.Body>
            
          </Card>
        </Grid>
      ))}
    </Grid.Container>
  </div>
  <article className='travel'>We offer personalized travel itineraries for all of our clients.</article>
  <div className='travelItinerary'>
    
  <button auto flat onClick={() => setVisible(true)}>
      Click here to check out a popular travel itinerary.
    </button>
  </div>  
  </div>
  );
}
export default Adventure;